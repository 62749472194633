<template>
  <layout>
    <b-col>
      <b-row v-for="group in Groups" :key="group.id">
        <b-col>
          <b-card v-if="group.price >0">
            <b-col>
              <b-row>
                <b-col style="display:flex;">
                  <i
                    class=" ri-currency-fill mr-4"
                    style="color:red;font-size:22px;margin-top:-10px;"
                  ></i>
                  <h6>Montant</h6>
                </b-col>
                <b-col>
                  <h6><b>{{ group.price }} </b>DZD</h6>
                </b-col>
              </b-row>

              <b-row>
                <b-col style="display:flex;">
                  <i
                    class=" ri-currency-fill mr-4"
                    style="color:red;font-size:22px;margin-top:-10px;"
                  ></i>
                  <h6>Statut</h6>
                </b-col>

                <b-col>
                  <h6>Non Payé</h6>
                </b-col>
              </b-row>
              <b-row>
                <b-col style="display:flex;">
                  <i
                    class="ri-calendar-todo-fill mr-4"
                    style="color:red;font-size:22px;margin-top:-10px;"
                  ></i>
                  <h6>Groupe</h6>
                </b-col>

                <b-col>
                  <h6>{{group.classtitle}} | {{group.classsubject}}</h6>
                </b-col>
              </b-row>
              <b-row class="mt-3">
                <b-col>
                  <b-button
                    block
                    style="background-color:darkred;border-radius:15px;"
                    @click="gotoinvoice(group)"
                    >Voir</b-button
                  >
                </b-col>
              </b-row>
            </b-col>
          </b-card>
        </b-col>
      </b-row>
    </b-col>
  </layout>
</template>
<script>
import Layout from "../../layouts/main";
import {
  ClassesDaysMethods,
  SessionsDatesMethods,
  studentClassMethods,
  classesMethods,StudentPaymentsMethods
} from "@/state/helpers";

export default {
  components: {
    Layout,
  },
  data() {
    return {
      Student: null,
      SessionsdateTom: [],
      Groups: [],
    };
  },
  created() {
    this.Student = JSON.parse(localStorage.getItem("user"));
    this.getStudentClass({ id: this.Student.id });
    this.getClasses({ id: this.Student.schoolId });
    this.getSessionsDates({ id: this.Student.schoolId });    
    this.getStudentPayments({ id: this.Student.id})
  },
  computed: {
    studentpayments() {
      return this.$store ? this.$store.state.studentpayments.StudentPayments : null;
    },
    classes() {
      return this.$store ? this.$store.state.classes.Classes : null;
    },
    sessiondates() {
      return this.$store ? this.$store.state.sessionsdates.SessionsDates : null;
    },
    studentsclass() {
      return this.$store ? this.$store.state.studentsclass.StudentsClass : null;
    },
  },
  watch: {
    sessiondates(val) {
      if (val != null) {
        this.InitData(val);
      }
    },
  },
  methods: {
    ...StudentPaymentsMethods,
    ...studentClassMethods,
    ...ClassesDaysMethods,
    ...SessionsDatesMethods,
    ...classesMethods,
    gotoinvoice(val) {
      localStorage.setItem('invoice', JSON.stringify(val));
      this.$router.push({
        path: `/invoice/`,
      });
    },
    InitData(val) {
      this.Groups = [];
      this.studentsclass.forEach((el) => {
        let clas = this.classes.filter((x) => x.id == el.classid);
        let sess = val.filter(
          (x) => x.classid == el.classid && x.ischecked == 0
        );
        let sess2 = this.studentpayments.filter(
          (x) => x.classeId == el.classid
        );
        let totalpayment = 0;
        sess2.forEach(x=>{
          totalpayment = totalpayment + x.total
        })
        let obj = {
          id: el.classid,
          schoolid: el.classid,
          classtitle: clas[0].title,
          classsubject: clas[0].subject,
          student:this.Student,
          pricepersession: clas[0].pricepersession,
          sessionsleft: sess.length,
          price: clas[0].pricepersession * sess.length,
          sessions: sess,
          totalpayment:totalpayment,
          payment:sess2
        };
        this.Groups.push(obj);
      });
    },
  },
};
</script>
